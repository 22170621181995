import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["relative group w-full flex items-center pl-4 py-3 text-sm rounded-l-2xl focus:outline-none", 
      _ctx.active
        ? 'bg-light-blue text-blue-600 font-normal'
        : 'font-light text-gray-200'
    ]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.activateRoute && _ctx.activateRoute(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", { active: _ctx.active }, undefined, true)
  ], 2))
}