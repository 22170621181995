import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(["mr-3 h-6 w-6 transition duration-500 ease-in-out", _ctx.active ? 'text-blue-600' : 'text-gray-300 group-hover:text-gray-100']),
    xmlns: "http://www.w3.org/2000/svg",
    fill: "none",
    viewBox: "0 0 24 24",
    stroke: "currentColor",
    "aria-hidden": "true"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}