
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    active: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    return {}
  }
})
