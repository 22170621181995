
import {useUserStore} from "@/store/user/UserStore";
import {computed, defineComponent} from "vue";
import UsersIcon from "../utils/icons/UsersIcon.vue";
import SidebarItemSingle from "./SidebarItemSingle.vue";
import {auth} from "@/firebase/config";
import HomeIcon from "../utils/icons/HomeIcon.vue";
import {useRoute, useRouter} from "vue-router";
import {RouteName} from "@/router/types";
import CalendarIcon from "../utils/icons/CalendarIcon.vue";
import IdentificationIcon from "../utils/icons/IdentificationIcon.vue";
import DocumentTextIcon from "@/components/utils/icons/DocumentTextIcon.vue";
import ContactIcon from "@/components/utils/icons/ContactIcon.vue";

export default defineComponent({
  name: "Sidebar",
  components: {
    DocumentTextIcon,
    SidebarItemSingle,
    UsersIcon,
    HomeIcon,
    CalendarIcon,
    IdentificationIcon,
    ContactIcon,
  },

  setup() {
    const userStore = useUserStore();
    const userState = userStore.getState();

    const name = computed(() => userState.name);

    const router = useRouter();

    const signOut = () => auth.signOut();

    const route = useRoute();
    const isClinicsSubmenuOpen = computed(
        () => !!route.matched.find((el) => el.name === RouteName.CLINICS)
    );

    const isDocumentationSubmenuOpen = computed(
        () => !!route.matched.find((el) => el.name === RouteName.DOCUMENTATION)
    );

    const goToProfile = () => router.push({name: RouteName.SETTINGS});
    return {
      userState,
      signOut,
      RouteName,
      isClinicsSubmenuOpen,
      name,
      goToProfile,
      isDocumentationSubmenuOpen,
    };
  },
});
