import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-screen flex overflow-hidden bg-dark-blue" }
const _hoisted_2 = { class: "flex flex-col w-64" }
const _hoisted_3 = { class: "flex flex-col h-0 flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Sidebar)
      ])
    ]),
    _createElementVNode("main", {
      class: _normalizeClass([_ctx.isDashboard? '' : 'overflow-y-auto', "bg-light-blue rounded-2xl my-4 mr-6 flex-1 relative z-0 overflow-hidden focus:outline-none"])
    }, [
      _createVNode(_component_router_view)
    ], 2)
  ]))
}