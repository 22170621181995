
import { RouteName } from "@/router/types";
import { computed, defineComponent, PropType } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  props: {
    route: {
      type: String as PropType<RouteName>,
      required: false,
    },
  },
  setup(props) {
    const currentRoute = useRoute();
    const router = useRouter();
    const active = computed(() => currentRoute.name === props.route);
    const activateRoute = () => {
      if (props.route) router.push({ name: props.route });
    };
    return { active, activateRoute };
  },
});
