
import { defineComponent } from "vue";
import IconWrapper from "./IconWrapper.vue";

export default defineComponent({
  components: { IconWrapper },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
