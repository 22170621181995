
import {computed, defineComponent, watchEffect} from "vue";

import Sidebar from "@/components/layout/Sidebar.vue";
import {RouteName} from "@/router/types";
import {useRoute} from "vue-router";

export default defineComponent({
  name: RouteName.DASHBOARD,
  components: {Sidebar},
  setup() {
    const route = useRoute()
    const isDashboard = computed(() => route.name === RouteName.DASHBOARD_HOME)
    return {isDashboard};
  },
});
